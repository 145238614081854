import React, { useState, useEffect } from 'react'
import { Route, Switch, useLocation } from 'wouter'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './config/firebaseConfig'
import { fetchUserInfo, isAdmin } from './utils/helpers'

// Page imports
import LoginPage from './pages/LoginPage'
import RegistrationPage from './pages/RegistrationPage'
import CreateDeal from './pages/CreateDeal'
import PortalPage from './pages/PortalPage'
import AdminPage from './pages/AdminPage'
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage'
import AccountDetailModal from './modals/AccountDetailModal'
import DealDetailPage from './pages/DealDetailPage'
import ViewFile from './pages/ViewFile'
import ProfilePage from './pages/ProfilePage'
import Loading from './components/Loading'

const ProtectedRoute = ({
    component: Component,
    user,
    path,
    userInfo,
    ...rest
}) => {
    const [, navigate] = useLocation()

    useEffect(() => {
        if (!user) {
            navigate('/')
            return
        }

        // Handle admin redirects
        if (path === '/portal' && isAdmin(user)) {
            navigate('/admin')
            return
        }

        // Force terms agreement for non-admin users
        if (
            user.email !== 'admin@quidity.net' &&
            userInfo &&
            !userInfo.agreedToTerms &&
            path !== '/terms'
        ) {
            navigate('/terms')
            return
        }
    }, [user, userInfo, path, navigate])

    if (!user) return null
    if (path === '/portal' && isAdmin(user)) return null

    return (
        <Route path={path}>
            <Component {...rest} user={user} userInfo={userInfo} />
        </Route>
    )
}

const PublicRoute = ({
    component: Component,
    user,
    path,
    userInfo,
    ...rest
}) => {
    const [, navigate] = useLocation()

    useEffect(() => {
        if (!user) return

        // Handle authenticated user redirects from public routes
        if (
            path === '/' ||
            path === '/register' ||
            path === '/create-account'
        ) {
            const initialPath = isAdmin(user) ? '/admin' : '/portal'

            // For non-admin users who haven't agreed to terms
            if (
                user.email !== 'admin@quidity.net' &&
                userInfo &&
                !userInfo.agreedToTerms
            ) {
                navigate('/terms')
            } else {
                navigate(initialPath)
            }
        }
    }, [user, userInfo, path, navigate])

    if (
        user &&
        (path === '/' || path === '/register' || path === '/create-account')
    ) {
        return null
    }

    return (
        <Route path={path}>
            <Component {...rest} />
        </Route>
    )
}

const NotFoundRoute = () => {
    const [, navigate] = useLocation()
    useEffect(() => {
        navigate('/')
    }, [navigate])
    return null
}

const Routes = () => {
    const [location, navigate] = useLocation()
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [isAccountDetailsModalOpen, setAccountDetailsModalOpen] =
        useState(null)
    const [selectedDeal, setSelectedDeal] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState(null)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setLoading(true)

            if (currentUser) {
                setUser(currentUser)
                const info = await fetchUserInfo(currentUser.uid)
                setUserInfo(info)

                // Handle initial routing based on user type and terms agreement
                if (currentUser.email === 'admin@quidity.net') {
                    if (location === '/') navigate('/admin')
                } else {
                    if (location === '/') navigate('/portal')
                    if (info && !info.agreedToTerms) navigate('/terms')
                }
            } else {
                setUser(null)
                setUserInfo(null)
                if (location === '/portal' || location === '/admin') {
                    navigate('/')
                }
            }

            setLoading(false)
        })

        return () => unsubscribe()
        // eslint-disable-next-line
    }, [])

    const closeAccountDetailsModal = () => {
        setSelectedAccount(null)
        setAccountDetailsModalOpen(false)
    }

    if (loading) {
        return (
            <div className='h-screen'>
                <Loading />
            </div>
        )
    }

    return (
        <div>
            <Switch>
                {/* Public Routes */}
                <PublicRoute
                    path='/'
                    component={LoginPage}
                    user={user}
                    userInfo={userInfo}
                />
                <PublicRoute
                    path='/register'
                    component={RegistrationPage}
                    user={user}
                    userInfo={userInfo}
                />
                <PublicRoute
                    path='/create-account'
                    component={RegistrationPage}
                    user={user}
                    userInfo={userInfo}
                />

                {/* Protected Routes */}
                <ProtectedRoute
                    path='/terms'
                    component={TermsAndConditionsPage}
                    user={user}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                />
                <ProtectedRoute
                    path='/create-deal'
                    component={CreateDeal}
                    user={user}
                    userInfo={userInfo}
                />
                <ProtectedRoute
                    path='/portal'
                    component={PortalPage}
                    user={user}
                    userInfo={userInfo}
                    setSelectedDeal={setSelectedDeal}
                />
                <ProtectedRoute
                    path='/profile'
                    component={ProfilePage}
                    user={user}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                />
                <ProtectedRoute
                    path='/admin'
                    component={AdminPage}
                    user={user}
                    userInfo={userInfo}
                    selectedDeal={selectedDeal}
                    setSelectedDeal={setSelectedDeal}
                    setSelectedAccount={setSelectedAccount}
                    setAccountDetailsModalOpen={setAccountDetailsModalOpen}
                />
                <ProtectedRoute
                    path='/deal/modify/:dealId'
                    component={DealDetailPage}
                    user={user}
                    userInfo={userInfo}
                />
                <ProtectedRoute
                    path='/view-file'
                    component={ViewFile}
                    user={user}
                    userInfo={userInfo}
                />

                {/* Catch all route */}
                <Route>
                    <NotFoundRoute />
                </Route>
            </Switch>

            {isAccountDetailsModalOpen && (
                <AccountDetailModal
                    user={user}
                    userInfo={userInfo}
                    selectedAccount={selectedAccount}
                    onClose={closeAccountDetailsModal}
                />
            )}
        </div>
    )
}

export default Routes
