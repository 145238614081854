// hooks/useFormValidation.js
import { getLastThreeMonths } from '../utils/helpers'

export const useFormValidation = (formData, isCheckboxChecked) => {
    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailPattern.test(email)
    }

    const isFieldValid = (field, type = 'text') => {
        if (!field) return false

        switch (type) {
            case 'email':
                return validateEmail(field)
            case 'tel':
                return field.toString().length === 10
            case 'zip':
                return field.toString().length === 5
            case 'ssn':
                return field.toString().length === 9
            case 'file':
                return field?.originalName || field?.name || false
            case 'text':
            default:
                return field.toString().trim() !== ''
        }
    }

    const areAllFieldsFilled = () => {
        const requiredFields = [
            // Contact Information
            { name: 'referrer', type: 'text' },
            { name: 'referrerEmail', type: 'email' },

            // Business Information
            { name: 'businessName', type: 'text' },
            { name: 'address', type: 'text' },
            { name: 'city', type: 'text' },
            { name: 'state', type: 'text' },
            { name: 'zip', type: 'zip' },
            { name: 'tel', type: 'tel' },
            { name: 'dateIncorporated', type: 'text' },
            { name: 'industry', type: 'text' },
            { name: 'businessDescription', type: 'text' },
            { name: 'useOfFunds', type: 'text' },

            // Owner Information
            { name: 'ownerName', type: 'text' },
            { name: 'ownerTel', type: 'tel' },
            { name: 'ownerEmail', type: 'email' },
            { name: 'ownerDOB', type: 'text' },
            { name: 'ownerSSN', type: 'ssn' },
            { name: 'pctOwnership', type: 'text' },

            // Additional Required Information
            { name: 'hasLargeLenderBalances', type: 'text' },

            // Required Documents
            { name: 'driversLicenseFront', type: 'file' },
            { name: 'loanApplication', type: 'file' },

            // Dynamic Bank Statements
            ...getLastThreeMonths().map((month) => ({
                name: `bankStatement${month.monthName}${month.year}`,
                type: 'file',
            })),
        ]

        const allFieldsValid = requiredFields.every(({ name, type }) =>
            isFieldValid(formData[name], type)
        )

        return allFieldsValid && isCheckboxChecked
    }

    const validateField = (name, value) => {
        const fieldConfig = [
            { name: 'referrerEmail', type: 'email' },
            { name: 'ownerEmail', type: 'email' },
            { name: 'tel', type: 'tel' },
            { name: 'ownerTel', type: 'tel' },
            { name: 'zip', type: 'zip' },
            { name: 'ownerSSN', type: 'ssn' },
            { name: 'driversLicenseFront', type: 'file' },
            { name: 'loanApplication', type: 'file' },
            ...getLastThreeMonths().map((month) => ({
                name: `bankStatement${month.monthName}${month.year}`,
                type: 'file',
            })),
        ].find((field) => field.name === name)

        return isFieldValid(value, fieldConfig?.type || 'text')
    }

    return {
        isFieldValid,
        areAllFieldsFilled,
        validateField,
    }
}
