import axios from 'axios'
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    getDocs,
} from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import moment from 'moment'

export const sendWelcomeEmail = (email, name) => {
    axios
        .post('https://eof8x5y3avaii7r.m.pipedream.net', {
            email,
            name,
        })
        .then(() => {
            console.log('Workflow triggered successfully.')
        })
        .catch((error) => {
            console.error('Error triggering workflow:', error)
        })
}

export const sendNewRegistrationEmailText = (
    companyName,
    ownerName,
    mobilephone,
    email
) => {
    const isoName = `${companyName}`
    const isoContact = `${ownerName}`

    const textContent = `New ISO ACCOUNT: ISO NAME: ${isoName} ISO CONTACT: ${isoContact} ISO EMAIL: ${email}`

    axios
        .post('https://eoktrmyieugbi4m.m.pipedream.net', {
            textContent,
        })
        .then(() => {
            console.log('Workflow triggered successfully.')
        })
        .catch((error) => {
            console.error('Error triggering workflow:', error)
        })

    axios
        .post('https://eof8x5y3avaii7r.m.pipedream.net', {
            email,
            name: isoName,
        })
        .then(() => {
            console.log('Workflow triggered successfully.')
        })
        .catch((error) => {
            console.error('Error triggering workflow:', error)
        })

    axios
        .post('https://eo803lnhl9mkvf.m.pipedream.net', {
            email,
            isoName,
            isoContact,
            date: `${moment().format('MM/DD/YYYY')}`,
            time: `${moment().format('hh:mm a')}`,
        })
        .then(() => {
            console.log('Workflow triggered successfully.')
        })
        .catch((error) => {
            console.error('Error triggering workflow:', error)
        })
}

export const sendStatusChangeEmail = async (
    referrer,
    referrerEmail,
    email,
    newStatus,
    businessName,
    message
) => {
    const emailSubject = `Status Update - ${businessName}`
    const referrerName = referrer.split(' ')[0]

    // Trigger the Pipedream workflow without waiting for the response
    await axios
        .post('https://eoihzjlkp7u3agl.m.pipedream.net', {
            email,
            referrerEmail,
            emailSubject,
            businessName,
            referrerName,
            status: newStatus,
            message,
            referrer,
        })
        .then(() => {
            console.log('Workflow triggered successfully.')
        })
        .catch((error) => {
            console.error('Error triggering workflow:', error)
        })

    // Continue with other tasks immediately
    console.log('Continuing execution without waiting for the email response.')
}

export const fetchUserInfo = async (user) => {
    try {
        const userRef = doc(db, 'users', user)

        // Reference to the user document in Firestore
        const docSnap = await getDoc(userRef)

        if (docSnap.exists()) {
            return docSnap.data() // Set the user info state with data from Firestore
        } else {
            console.log('No such document!')
            return null
        }
    } catch (error) {
        console.error('Error fetching user data:', error)
    }
}

export const industryCategories = {
    Agriculture: ['None of the below', 'Non-profit'],
    'Automotive Repair': [
        'Body repair, collision, painting',
        'General repair, truck, rv',
        'Parts, accessories',
        'Quick service lube, tune-up',
        'Tire, glass',
        'Transmission',
        'Car wash',
        'Upholstery',
        'Non-profit',
    ],
    'Automobile Sales & Gas Services Stations': [
        'None of the below',
        'Automotive dealerships new/used',
        'Gas station/Convenience',
        'New & Used car dealers',
        'RV dealers',
        'Non-profit',
    ],
    'Business Services': [
        'None of the below',
        'Advertising sales',
        'Appraisal services',
        'Billing services',
        'Direct/Multi-level marketing',
        'Mail center',
        'Printing/graphic design',
        'Staffing/temp agencies',
        'Non-profit',
    ],
    Communications: ['None of the below', 'Non-profit'],
    'Construction & Contractors': [
        'None of the below',
        'Construction contractors',
        'Electricians',
        'Flooring',
        'Heating/Air conditioning/HVAC',
        'House remodeling/improvements (ir. Structural remodeling)',
        'Housing related',
        'Painters',
        'Plumbers',
        'Roofing/siding',
        'Tile/carpet',
        'Windows',
        'Non-profit',
    ],
    'Eating & Drinking Places': [
        'None of the below',
        'Bar',
        'Catering',
        'Ice cream shops',
        'Restaurant - casual dining',
        'Restaurant - fast food & carry out',
        'Restaurant - fine dining',
        'Non-profit',
    ],
    'Educational Services': [
        'None of the below',
        'Daycare',
        'Schools',
        'Schools - educational training certifications',
        'Schools - private/elementary/high school',
        'Seminars',
        'Non-profit',
    ],
    'Entertainment Services': [
        'None of the below',
        'Adult entertainment',
        'Art galleries',
        'Casinos',
        'Cruise ships',
        'Entertainment venue',
        'Event planning',
        'Ticket brokers',
        'Travel agencies',
        'Non-profit',
    ],
    Finance: [
        'None of the below',
        'Accounting',
        'Bail bonds',
        'Check cashing companies',
        'Collection agencies',
        'Consumer credit counseling services',
        'Payday loan companies',
        'Tax return preparation services',
        'Non-profit',
    ],
    'Food & Beverage Stores': [
        'None of the below',
        'Food- bakery',
        'Food - convenience & variety',
        'Food - deli, meat, seafood, other',
        'Food - grocery store',
        'Liquor store',
        'Non-profit',
    ],
    Freight: [
        'None of the below',
        'Tow truck and yard',
        'Tractor trailer transportation',
        'Non-profit',
    ],
    'Health Services': [
        'None of the below',
        'Dental',
        'Hair replacements/clubs',
        'Health club',
        'Health club monthly membership',
        'Marijuana shop',
        'Medical services',
        'Pharmacy',
        'Spa Weightless centers',
        'Non-profit',
    ],
    'Hotels & Lodging': [
        'None of the below',
        'Hotel & motel',
        'Timeshares',
        'Non-profit',
    ],
    Insurance: [
        'None of the below',
        'Insurance',
        'Warranty companies',
        'Non-profit',
    ],
    'Legal Services': [
        'None of the below',
        'Law firms',
        'Professional service (All doctors, lawyers)',
        'Non-profit',
    ],
    Manufacturing: ['None of the below', 'Non-profit'],
    'Mining & Natural Resource Extraction': ['None of the below', 'Non-profit'],
    'Miscellaneous Services': [
        'None of the below',
        'Equipment rental',
        'Guard dog service',
        'Internet prescription',
        'Other service retail',
        'Non-profit',
    ],
    'Personal Services': [
        'None of the below',
        'Annual memberships',
        'Dry cleaners',
        'Hair & nail salon',
        'Interior design',
        'Lawn & garden',
        'Maid & cleaning services',
        'Online dating/memberships',
        'Pet washing & grooming',
        'Tanning & tattoo',
        'Non-profit',
    ],
    'Real Estate': ['None of the below', 'Property management/real estate'],
    'Religious Services': [
        'None of the below',
        'Churches',
        'Funeral homes',
        'Non-profit',
    ],
    Retail: [
        'None of the below',
        'Antiques/collectibles',
        'Auctions houses',
        'Bridal shop',
        'Clothing & apparel',
        'Computer-related retail',
        'Ebay sales',
        'Electronics',
        'Firearms',
        'Flea market',
        'Florists',
        'Furniture',
        'Furriers',
        'Golf- stores, driving ranges, country clubs',
        'Heating oil',
        'Home appliance stores',
        'Home based door/door',
        'Home entertainment systems',
        'Internet sales (no storefront)',
        'Internet sales (storefront)',
        'Internet software downloads',
        'Jewelry',
        'Kiosks',
        'Mattresses',
        'Pet store',
        'Pools/spas',
        'Sporting goods',
        'Tobacco shop',
        'Wireless phone/accessories',
        'Other product retail',
        'Non-profit',
    ],
    Storage: ['None of the below', 'Boating/marina', 'Non-profit'],
    Transportation: [
        'None of the below',
        'Airlines',
        'Daycare services - home based not allowed',
        'Day tours',
        'Limousine & transportation',
        'Non-profit',
    ],
    Wholesale: ['None of the below', 'Import/export', 'Non-profit'],
    'Vehicle Rental': ['None of the below', 'Non-profit'],
}

export const isAdmin = (user) => {
    return user?.email === 'admin@quidity.net'
}

// Helper function to get the last three months
export const getLastThreeMonths = () => {
    const months = []
    const currentDate = new Date()
    for (let i = 1; i < 4; i++) {
        const date = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - i,
            1
        )
        const monthName = date.toLocaleString('default', { month: 'long' })
        const year = date.getFullYear()
        months.push({ monthName, year })
    }
    return months
}

// Helper function to get the last two years
export const getLastTwoYears = () => {
    const currentYear = new Date().getFullYear()
    return [currentYear - 1, currentYear - 2]
}
export const getLastThreeYears = () => {
    const currentYear = new Date().getFullYear()
    return [currentYear - 1, currentYear - 2, currentYear - 3]
}

// Simple cache object
const userCache = new Map()

/**
 * Find a user document by email address with caching
 * @param {string} email - The email address to search for
 * @param {boolean} [useCache=true] - Whether to use cached results
 * @returns {Promise<Object|null>} The user document or null if not found
 */
export const findUserByEmail = async (email, useCache = true) => {
    try {
        // Check cache first
        if (useCache && userCache.has(email)) {
            return userCache.get(email)
        }

        // Create a query against the users collection
        const usersRef = collection(db, 'users')
        const q = query(usersRef, where('email', '==', email))

        // Execute the query
        const querySnapshot = await getDocs(q)

        if (querySnapshot.empty) {
            console.log('No user found with this email')
            userCache.set(email, null) // Cache the null result
            return null
        }

        // Get the first matching document
        const userDoc = querySnapshot.docs[0]
        const userData = {
            userId: userDoc.id,
            ...userDoc.data(),
        }

        // Cache the result
        userCache.set(email, userData)

        return userData
    } catch (error) {
        console.error('Error finding user by email:', error)
        return null
    }
}

// Optional: Clear cache method
export const clearUserCache = () => {
    userCache.clear()
}
