// LoginPage.js
import React, { useEffect, useState } from 'react'
import {
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
} from 'firebase/auth'
import { auth } from '../config/firebaseConfig'
import { useLocation } from 'wouter'

const LoginPage = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    // eslint-disable-next-line
    const [location, navigate] = useLocation()

    const [loading, setLoading] = useState(false)

    const getErrorMessage = (error) => {
        let message
        switch (error) {
            case 'Firebase: Error (auth/invalid-credential).':
                message = 'You have entered an invalid username or password'
                break
            default:
                return null
        }

        return message
    }

    const handleLogin = (e) => {
        e.preventDefault()
        if (email && password) {
            setLoading(true)
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    if (userCredential) {
                        setLoading(false)
                        if (userCredential.user.email === 'admin@quidity.net') {
                            navigate('/admin')
                        } else {
                            navigate('/portal')
                        }
                    }
                    // Login successful.
                    // Redirect to the portal page or handle the login success scenario.
                })
                .catch((error) => {
                    // Handle Errors here.
                    setLoading(false)
                    console.log(getErrorMessage(error.message))
                    setError(getErrorMessage(error.message))
                })
        }
    }

    const handlePasswordReset = () => {
        setError(false)
        if (!email) {
            setError(
                'Please enter your email address to receive a password reset link.'
            )
            return
        }

        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert('Password reset email sent! Check your inbox.')
            })
            .catch((error) => {
                setError(getErrorMessage(error.message))
            })
    }

    const [isScrollable, setIsScrollable] = useState(false)

    useEffect(() => {
        const checkScrollable = () => {
            // Check if the body height is greater than the window height
            setIsScrollable(document.body.scrollHeight > window.innerHeight)
        }

        // Check on initial load
        checkScrollable()

        // Add event listener for window resize
        window.addEventListener('resize', checkScrollable)

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', checkScrollable)
    }, [])

    return (
        <div
            className={`shadow-lg rounded-lg bg-themeColorPrimary3 ${
                isScrollable ? 'pt-[150px]' : ''
            } min-h-screen flex flex-col md:flex-row justify-center items-center gap-8 p-5`}
        >
            <div className='bg-white p-8 rounded-xl shadow-md text-center max-w-full md:max-w-md lg:max-w-lg xl:max-w-xl'>
                <h2 className='text-lg md:text-xl lg:text-2xl mb-4 font-bold text-themeColorPrimary2'>
                    Please enter your email and password
                </h2>
                {error && <p className='text-red-500 mb-4'>{error}</p>}
                <form onSubmit={handleLogin}>
                    <div className='mb-4'>
                        <input
                            type='email'
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker'
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='mb-6'>
                        <input
                            type='password'
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <p className='mt-2 mr-auto w-fit'>
                            <p
                                onClick={handlePasswordReset}
                                className='text-themeColorPrimary hover:text-themeColorPrimary2 font-bold cursor-pointer'
                            >
                                Forgot password?
                            </p>
                        </p>
                    </div>
                    <div className='flex items-center justify-between mb-6'>
                        <label className='inline-flex items-center'>
                            <input type='checkbox' className='form-checkbox' />
                            <span className='ml-2 cursor-pointer'>
                                Remember me?
                            </span>
                        </label>
                    </div>
                    <div className='flex items-center justify-between'>
                        <button
                            type='submit'
                            className='mx-auto mt-2 mb-2 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-[80%] disabled:hover:bg-themeColorPrimary'
                        >
                            {!loading ? (
                                'Login'
                            ) : (
                                <div className='flex items-center'>
                                    <div className='button-loader mr-2'></div>
                                    Logging in
                                </div>
                            )}
                        </button>
                    </div>
                    <p className='text-center my-4'>
                        Not a member?{' '}
                        <a
                            href='/register'
                            className='text-themeColorPrimary font-bold'
                        >
                            Create an account
                        </a>
                    </p>
                </form>
            </div>

            <div className='max-w-full md:max-w-xl p-5'>
                <h1 className='text-2xl font-bold text-themeColorPrimary'>
                    Welcome to the Quidity Partner Portal
                </h1>
                <p className='mt-2 text-themeColorPrimary2'>
                    Your gateway to streamlined application submission and
                    management.
                </p>

                <h2 className='mt-4 text-lg font-semibold text-gray-800'>
                    Please Login to Continue
                </h2>
                <p className='mt-2 text-themeColorPrimary2'>
                    Access your account to submit new applications, track
                    existing ones, and manage client information with ease.
                </p>

                <p className='mt-4 text-themeColorPrimary2'>
                    At Quidity, we are committed to providing our partners with
                    a seamless, efficient, and secure experience.
                </p>
                <p className='mt-2 text-themeColorPrimary2'>
                    Your partnership is valuable to us, and we strive to support
                    you in every step of your journey towards facilitating
                    business growth and success.
                </p>

                <h3 className='mt-4 text-lg font-semibold text-gray-800'>
                    Not a registered partner yet?
                </h3>
                <p className='mt-2 text-themeColorPrimary2'>
                    Contact us to learn how you can join our network of esteemed
                    brokers and representatives.
                </p>

                <div className='mt-4'>
                    <p className='text-gray-800 font-semibold'>AJ Borinsky</p>
                    <a
                        href='mailto:aborinsky@quidity.fund'
                        className='text-themeColorPrimary font-semibold'
                    >
                        aborinsky@quidity.fund
                    </a>
                    <p className='text-gray-800'>908-864-3009</p>
                </div>
            </div>
        </div>
    )
}

export default LoginPage
